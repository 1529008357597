<!--
 * @Author: your name
 * @Date: 2020-11-04 14:43:18
 * @LastEditTime: 2020-11-26 11:56:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/help/HepDetail.vue
-->
<template>
  <page-main>
    <div class="card-lay">
      <div class="title font-weight">问题反馈</div>
      <el-input type="textarea" :rows="12" v-model="text" :show-word-limit="true" :maxlength="maxlength" placeholder="请描述您当前遇到的问题"></el-input>
      <div class="content">
        <div class="p-margin">上传页面截图（至多5张）</div>
        <div>
          <ImageUpload @onSuccess="onUploadSuccess" :imageList="imgList" />
        </div>
      </div>
      <el-row justify="end" type="flex">
        <el-button type="primary" round :loading="loading" @click="onSubmit" class="btn">提交</el-button>
      </el-row>
    </div>
  </page-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ImageUpload from '@/bizComponents/ImageUpload'
export default {
  name: 'HelpFeedBack',
  data() {
    return {
      text: '',
      loading: false,
      maxlength: 500,
      imgList: []
    }
  },
  components: {
    ImageUpload
  },
  computed: {},
  methods: {
    ...mapActions(['onFeedBack']),
    onUploadSuccess(image) {
      this.imgList = image.map(v => {
        return {
          imageName: v.imageName,
          normal: v.normal,
          uid: v.uid
        }
      })
    },
    onSubmit() {
      if (this.loading) return
      if (!this.text.length) return this.$message.error('请描述您遇到的问题')
      this.loading = true
      let images = this.imgList.map(v => v.imageName)
      this.onFeedBack({ content: this.text, imageUrls: images,feedBackType: this.$route.query.feedBackType }).then(
        res => {
          this.loading = false
          this.$message.success('问题反馈已反馈至后台')
          this.text = ''
          this.imgList = []
        },
        rea => {
          this.loading = false
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  width: 150px;
}

.card-lay {
  max-width: 700px;
  padding: 20px 30px;
}

.title {
  color: #272E40;
  font-size: 20px;
  padding-bottom: 25px;
}

.common-margin {
  margin-right: 30px;
}

.content {
  padding-top: 10px;
  color: #000;
}

.bottom-content {
  padding-top: 30px;
  padding-bottom: 30px;
}

.radius-box {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 1px solid #909399;
  color: #909399FF;
  display: flex;
  margin: 0 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;

  &.active {
    border-color: #3D61E3FF;
    color: #3D61E3FF;
  }
}

.digg-img {
  height: 18px;
  margin-bottom: 5px;
}

.p-margin {
  margin-top: 10px;
  margin-bottom: 15px;
}

.content {
  padding-bottom: 30px;
}
</style>
