<!--
 * @Author: your name
 * @Date: 2020-11-04 14:43:18
 * @LastEditTime: 2020-12-22 17:02:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/help/HepDetail.vue
-->
<template>
  <page-main v-loading='loading' id='help-detail'>
    <div class="card-lay">
      <div class="title font-weight">{{helpMessageTitle}}</div>
      <el-row class="desc color-info">
        <span class="common-margin">{{publishAt}}</span>
        <span>{{viewCount | countFilter}}浏览</span>
      </el-row>
    </div>
    <div class="content color-info">
      <div v-safe-html='helpMessageContent' ref='box' @click="onPreview($event)"></div>
    </div>
    <el-row class="bottom-content" type='flex' justify="center">
      <div class="radius-box" @click="onLike" v-bind:class='{active:like}'>
        <img src="../../assets/help/like_active.png" alt="" v-if="like" class="digg-img">
        <img src="../../assets/help/like_default.png" alt="" v-else class="digg-img">
        有用{{likeCount | countFilter}}
      </div>
      <div class="radius-box" @click="onHate" v-bind:class='{active:hate}'>
        <img src="../../assets/help/hate_active.png" alt="" v-if="hate" class="digg-img">
        <img src="../../assets/help/hate_default.png" alt="" v-else class="digg-img">
        没用{{hateCount | countFilter}}
      </div>
    </el-row>
    <el-dialog :visible.sync="showPreviewDialog" width='80%'>
      <img width="100%" :src="previewImageSrc" alt="">
    </el-dialog>
  </page-main>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'HelpDetail',
  data () {
    return {
      articleId: '',
      loading: false,
      showPreviewDialog: false,
      previewImageSrc: '',
      title: ''
    }
  },
  computed: {
    ...mapGetters(['helpDetail','helpMessageTitle','publishAt', 'viewCount', 'helpMessageContent', 'likeCount', 'hateCount','like', 'hate']),
  },
  filters: {
    countFilter(count) {
      return count > 999 ? `${count}+` : count
    }
  },
  updated () {
    if (this.$refs.box) {
      let images = this.$refs.box.querySelectorAll('img') || []
			for (var i = images.length - 1; i >= 0; i--) {
			  this.$refs.box.querySelectorAll('img')[i].add
				this.$refs.box.querySelectorAll('img')[i].style.maxWidth = '100%'
				this.$refs.box.querySelectorAll('img')[i].style.height = 'auto'
				this.$refs.box.querySelectorAll('img')[i].style.cursor = 'pointer'
			}
    }
  },
  methods: {
    ...mapActions(['onLikeHelp','onHateHelp','getHelpDetail']),
    onPreview(e) {
      if (e.target.nodeName === 'IMG') {
        this.previewImageSrc = e.target.src
        this.showPreviewDialog = true
      }
    },
    onLike() {
      this.onLikeHelp({articleId:this.articleId,like:!this.like})
    },
    onHate () {
      this.onHateHelp({articleId:this.articleId,hate:!this.hate})
    },
    init () {
      this.articleId = this.$route.params.id
      this.loading = true
      this.getHelpDetail(this.articleId).then(res => {
        this.loading = false
      }, rea=>{
        this.$message.error(rea)
        this.loading = false
      })
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
  .card-lay {
    padding: 15px 50px 20px 50px;
    margin-right: -20px;
    margin-left: -20px;
    border-bottom: 1px solid rgba(233, 234, 235, 1);
    .title {
      color: #272E40;
      font-size: 24px;
      padding-bottom: 15px;
    }
    .common-margin {
      margin-right: 30px;
    }
  }
  .content {
    padding: 30px 20px;
    text-align: justify;
    min-height: 500px;
    img {
      max-width: 100%;
    }
  }
  .bottom-content {
    padding-top: 30px;
    padding-bottom: 30px;
    .radius-box {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      border: 1px solid #909399;
      color: #909399FF;
      display: flex;
      margin: 0 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12px;
      &.active {
        border-color: #3D61E3FF;
        color: #3D61E3FF;
      }
      .digg-img {
        height: 18px;
        margin-bottom: 5px;
      }
    }
  }
</style>
