<!--
 * @Author: your name
 * @Date: 2020-11-04 14:58:20
 * @LastEditTime: 2020-12-31 14:22:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/help/HelpList.vue
-->
<template>
  <page-main :body-style='cardBodyStyle'>
    <el-row type="flex" justify="space-between" align="middle" class="title-box">
      <span class="color-impontant font-weight font-max">{{titleText}}</span>
      <div class="input-box">
        <el-input v-model='searchText' prefix-icon='el-icon-search' placeholder= '搜索您想了解的问题' @change='onSearch'></el-input>
      </div>
    </el-row>
    <el-row class="infinite-list-wrapper flex-column" type='flex' align='middle' justify='center'  v-if='isEmpty'>
      <img src="../../assets/common/empty.png" alt="">
      <div class="color-info p-m">{{emptyText}}</div>
      <el-button type="primary" round @click="onPase">问题反馈</el-button>
    </el-row>
    <div class='infinite-list-wrapper' v-else>
      <help-item v-for='item in helpList' :key='item.id' :item='item' @onRead='onReadHelp' @onJump='onJumpHelpDetail' />
    </div>
    <el-row type='flex' justify="end" class="t-p">
      <el-pagination
        background
        hide-on-single-page
        size='large'
        layout="prev, pager, next"
        :page-size='pageSize'
        :current-page="pageNo"
        @current-change='onChange'
        :total='totalCount'
      ></el-pagination>
    </el-row>
  </page-main>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import HelpItem from '@/bizComponents/HelpItem'
export default {
  name: 'HelpList',
  data () {
    return {
      pageId: '',
      pageNo: 1,
      pageSize: 10,
      loading: false,
      totalCount: 0,
      isEmpty: false,
      isSearch: false,
      isEnd: false,
      searchText: '',
      title: '',
      cardBodyStyle: {height:'100%',display: 'flex',flexDirection: 'column'}
    }
  },
  components: {
    HelpItem
  },
  computed: {
    ...mapGetters(['helpList']),
    disabled () {
      return this.loading || this.isEnd
    },
    titleText () {
      if (this.isSearch) {
        return `搜索 “${this.searchText}”相关的结果，共${this.totalCount}条`
      } else {
        return this.title
      }
    },
    categoryId () {
      return this.searchText ? '' : this.pageId
    },
    categoryName () {
      return this.searchText ? '' : this.title
    },
    emptyText () {
      if (this.isSearch) {
        return '暂无搜索结果'
      } else {
        return '暂无相关问题'
      }
    }
  },
  methods: {
    ...mapActions(['initHelpMessageList','getHelpMessageList', 'onReadHelp']),
    onPase () {
      this.$router.push('/help/feedback')
    },
    onSearch (t) {
      this.pageNo = 1
      this.onInit()
    },
    onChange (pageNo) {
      this.pageNo = pageNo
      this.onInit()
    },
    onJumpHelpDetail (id = '') {
      this.$router.push(`/help/detail/${id}`)
    },
    initPage () {
      this.pageId = this.$route.params.id || ''
      this.title = this.$route.query.name
      this.pageNo = 1
      this.loading = false
      this.totalCount = 0
      this.isSearch = false
      this.isEnd = false
      this.searchText = ''
      this.onInit()
    },
    onInit () {
      this.isEnd = false
      this.loading = true
      this.initHelpMessageList({pageNo:this.pageNo,categoryId:this.categoryId, searchKey:this.searchText,pageSize:this.pageSize, categoryName: this.categoryName}).then(res => {
        const {totalCount, data} = res
        this.loading = false
        this.isSearch = !!this.searchText
        this.isEmpty = !data.length
        this.totalCount = totalCount
      },rea => {
        this.loading = false
      })
    },
    onLoad () {
      if (this.loading || this.isEnd) return
      this.loading = true
      this.getHelpMessageList({pageNo:this.pageNo,categoryId:this.categoryId, searchKey:this.searchText,categoryName: this.categoryName}).then(res => {
        const {totalCount, data} = res
        this.loading = false
        this.pageNo++
        this.isEnd = !data.length && this.pageNo > 1
        this.totalCount = totalCount
      }, rea=> {
        this.loading = false
      })
    }
  },
  watch: {
    $route (to, from) {
      if (to.name === 'HelpList' && to.params.id !== this.pageId) {
        this.initPage()
      }
    }
  },
  activated () {
  },
  mounted () {
    this.pageId = this.$route.params.id
    this.title = this.$route.query.name
    this.onInit()
  }
}
</script>
<style lang="scss" scoped>
  .el-card {
    .el-card__body {
      padding: 35px 25px;
    }
  }
  .title-box {
    padding-top: 10px;
    padding-bottom: 30px;
    flex-shrink: 0;
  }
  .font-max {
    font-size: 20px;
  }
  .p-margin {
    margin-left: 12px;
  }
  .infinite-list-wrapper {
    border-top: 1px solid rgba(233, 234, 235, 1);
    min-height: 600px;
    // flex: 1;
  }
  .help-item {
    line-height: 1;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(233, 234, 235, 1);
  }
  .p-m {
    margin-bottom: 15px;
  }
  .t-p {
    flex-shrink: 0;
  }
</style>
